import {
  useApolloClient,
  useQuery,
  useSubscription,
} from "@apollo/react-hooks";
import { Card, Loading, Toast } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { get, isEqual, startCase } from "lodash";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { AppContext } from "../../context";
import { GET_MAPPING_ORDER_AGGREGATION } from "../../graphql/queries";
import { MAPPING_EVENTS } from "../../graphql/subscription";
import {
  convertObjectToParams,
  convertStringToObject,
  convertToOffset,
  convertToPaged,
  getCookie,
  getTeamID,
  handleError,
  matchPathName,
  needGetTotalMapping,
  removeFieldWithoutFilter,
  setCookie,
} from "../../helper";
import history from "../../history";
import useToggle from "../../hooks/useToggle";
import { COMMON_FILTER, TEAM_ROLE, USER_ROLE } from "../../variable";
import { FiltersOrderPolaris } from "../order/FiltersOrderPolaris";
import { EmptyStatePolaris } from "../shared/EmptyStatePolaris";
import { SkeletonPagePolaris } from "../shared/SkeletonPagePolaris";
import { MOrderContext } from "./context";
import { MappingOrdersFiltersPolaris } from "./MappingOrdersFiltersPolaris";
// import { TableMappingOrdersPolaris } from "./TableMappingOrdersPolaris";
import { TableMOPolaris } from "./TableMOPolaris";

const TABS_MAPPING_ORDER = [
  { name: "All", key: null },
  { name: "Online Stores", key: "Online Stores" },
  { name: "Ebay", key: "Ebay" },
  { name: "Amazon", key: "Amazon" },
  { name: "Etsy", key: "Etsy" },
  { name: "Facebook", key: "Facebook" },
  { name: "Tiktok", key: "Tiktok" },
];

export const LIST_MAPPING = gql`
  query listMappingOrder($filter: MappingFilter) {
    listMappingOrder(filter: $filter) {
      total
      onlineStoreTotal
      ebayTotal
      amazonTotal
      etsyTotal
      facebookTotal
      tiktokTotal
      nodes {
        id
        originOrder
        orderId
        orderData
        metaData
        sku
        store {
          id
        }
        store {
          id
          title
          platform
        }
        personalized
        quantity
        images
        product
        productId
        variantId
        sku
        price
        variants
        ebayOriginId
        needVariantMapping
        createdAt
        shippingService
        needMappingExistProduct
        existProductID
        note
        label
      }
    }
  }
`;

const FIELD_FILTER = [...COMMON_FILTER, "groupId", "storeId", "type"];

export const MappingOrdersPolaris = (props) => {
  const { path } = props;
  const isMatchPathName = useMemo(() => matchPathName(path), [path]);

  const initFilter = useMemo(() => {
    let initialFilter = {
      ...convertStringToObject(history.location.search),
    };
    if (initialFilter) {
      let limit = initialFilter.limit;
      limit = Number(limit);
      if (!limit) {
        limit = 10;
      }
      initialFilter.limit = limit;

      if (initialFilter.paged) {
        initialFilter.offset = convertToOffset(
          initialFilter.limit,
          Math.round(initialFilter.paged),
        );
      }

      initialFilter = removeFieldWithoutFilter(initialFilter, FIELD_FILTER);
    }
    return initialFilter;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMatchPathName]);

  // State
  const [toastActive, toggleToast] = useToggle(false);
  const [notify, notifyChange] = useState({ msg: null, err: false });
  const [agg, setAgg] = useState({});
  const [total, setTotal] = useState(0);
  const [doAction, setDoAction] = useState(false);
  const typeRef = useRef(false);
  // Hooks
  const client = useApolloClient();

  const [filter, setFilter] = useState({
    limit: Number(getCookie("perPageMappingOrders")) || 20,
    offset: 0,
    search: null,
    type: null,
    storeId: null,
    time: null,
    groupId: null,
    ...initFilter,
  });

  const { store, currentUser } = useContext(AppContext);
  const userRole = get(currentUser, "roles", []);
  const teamRole = get(currentUser, "teamUser.role", null);
  let isMarketplaceManager = false;
  if (userRole.includes(USER_ROLE.Seller)) {
    if (
      [TEAM_ROLE.MarketplaceManager].includes(teamRole) &&
      store &&
      store.id === "all"
    ) {
      isMarketplaceManager = true;
    }
  }

  // Queries
  const { data, loading, error, refetch } = useQuery(
    LIST_MAPPING,
    {
      variables: {
        filter: {
          ...filter,
          ...(!isMarketplaceManager
            ? { storeId: store ? store.id : filter.storeId }
            : null),
        },
      },
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      onCompleted: async (res) => {
        let type = filter.type;
        const newArgs = await customQuery(filter);
        const total = get(res, "listMappingOrder.total", 0);
        if (type == null) {
          // setTotal(get(res, "listMappingOrder.total", 0));
          setTotal((prev) => {
            if (prev !== Number(total)) {
              return total;
            }

            return prev;
          });
        } else {
          if (type === "online store") {
            type = "online_stores";
          }
          const mapType = newArgs[type];
          if (mapType?.doc_count != null) {
            const prevTotal = Object.values(newArgs).reduce(
              (acc, cur) =>
                cur?.doc_count ? (acc += Number(cur.doc_count)) : acc,
              0,
            );

            const newTotal = prevTotal + total - Number(mapType.doc_count);
            setTotal(newTotal);
          }
        }
      },
    },
  );

  // Subcription
  const teamID = getTeamID(currentUser);
  const { data: dataSup } = useSubscription(MAPPING_EVENTS);

  useEffect(() => {
    if (teamID === "Xeya4") return;
    
    const valid = needGetTotalMapping(dataSup, teamID);
    if (valid && !doAction) {
      refetch();
    }
  }, [dataSup, teamID, doAction]);

  // Get data
  const customQuery = useCallback(async (filter) => {
    const type = filter.type;
    if (type != null && !typeRef.current) {
      const res = await client.query({
        query: GET_MAPPING_ORDER_AGGREGATION,
        variables: {
          filter: { ...filter, type: null },
        },
      });
      const data = res.data;
      getAggregations(data);

      const resTotal = await client.query({
        query: LIST_MAPPING,
        variables: {
          filter: { ...filter, type: null },
        },
      });
      const data2 = resTotal.data;
      setTotal(get(data2, "listMappingOrder.total", 0));
    }

    const res = await client.query({
      query: GET_MAPPING_ORDER_AGGREGATION,
      variables: {
        filter,
      },
      fetchPolicy: "no-cache",
    });
    const data = res.data;
    typeRef.current = true;
    return getAggregations(data, filter.type);
  }, []);

  const getAggregations = useCallback(
    (data, type) => {
      const prefix = "getMappingOrderAggregation";
      const amazon = get(data, `${prefix}.amazonTotal`, 0);
      const ebay = get(data, `${prefix}.ebayTotal`, 0);
      const etsy = get(data, `${prefix}.etsyTotal`, 0);
      const online_stores = get(data, `${prefix}.onlineStoreTotal`, 0);
      // const total = get(data, "listMappingOrder.total", 0);
      const facebook = get(data, `${prefix}.facebookTotal`, 0);
      const tiktok = get(data, `${prefix}.tiktokTotal`, 0);
      const aggregations = {
        amazon: { doc_count: amazon },
        ebay: { doc_count: ebay },
        etsy: { doc_count: etsy },
        online_stores: { doc_count: online_stores },
        facebook: { doc_count: facebook },
        tiktok: { doc_count: tiktok },
      };

      type = type === "online store" ? "online_stores" : type;
      let tmpAgg = {};
      setAgg((prev) => {
        const nextAgg = updateAgg(type, prev, aggregations);
        tmpAgg = nextAgg;
        return nextAgg;
      });

      return tmpAgg;
    },
    [filter.type],
  );

  useEffect(() => {
    let { offset, limit, time, ...rest } = filter;
    let params = null;
    let paged = convertToPaged(limit, offset);
    params = convertObjectToParams({
      limit,
      paged,
      ...rest,
    });
    history.push(`${history.location.pathname}?${params}`);
  }, [filter]);

  const loadingMarkup = loading && <Loading />;

  let defaultType = null;
  if (filter && filter.type) {
    defaultType = startCase(filter.type);
    if ("Online Store" === defaultType) {
      defaultType = "Online Stores";
    }
  }

  // Handle actions
  const handlePagination = useCallback((offset, limit) => {
    setFilter((prev) => ({
      ...prev,
      offset,
      limit,
    }));
    setCookie("perPageMappingOrders", limit, 100);
  }, []);

  const handleFilterChange = useCallback(
    ({ search, storeId, time, groupId }) => {
      setFilter((prevState) => {
        if (
          !isEqual(prevState.search, search) ||
          !isEqual(prevState.storeId, storeId) ||
          !isEqual(prevState.time, time) ||
          !isEqual(prevState.groupId, groupId)
        ) {
          prevState.offset = 0;
        }

        return {
          ...prevState,
          search,
          ...(!isMarketplaceManager ? { storeId } : null),
          time,
          groupId,
          offset: 0,
        };
      });
    },
    [isMarketplaceManager],
  );

  // Markup
  const toastMarkup = toastActive && notify.msg && (
    <Toast
      content={notify.msg}
      error={notify.err}
      onDismiss={() => toggleToast(false)}
      duration={1500}
    />
  );

  // Variables
  const provider = {
    toggleToast,
    notifyChange,
    setAgg,
    filter,
  };

  return (
    <>
      {loadingMarkup}
      {toastMarkup}
      <Card sectioned>
        <MappingOrdersFiltersPolaris
          filter={filter}
          onChange={handleFilterChange}
        />
      </Card>
      <Card>
        <FiltersOrderPolaris
          value={TABS_MAPPING_ORDER}
          defaultValue={defaultType}
          aggregations={agg}
          total={total}
          onChange={({ status }) => {
            status = status && status.toLowerCase();
            if ("online stores" === status) {
              status = "online store";
            }
            setFilter((prevState) => {
              if (!isEqual(prevState.status, status)) {
                prevState.offset = 0;
              }
              return { ...prevState, type: status };
            });
          }}
        />
        {error && <div>Error: {handleError(error.toString())}</div>}
        {loading ? (
          <SkeletonPagePolaris />
        ) : data?.listMappingOrder?.nodes?.length > 0 ? (
          <MOrderContext.Provider value={provider}>
            <TableMOPolaris
              data={data}
              total={total}
              agg={agg}
              filter={filter}
              setFilter={handlePagination}
              refetch={refetch}
              setDoAction={setDoAction}
            />
          </MOrderContext.Provider>
        ) : (
          <EmptyStatePolaris />
        )}
      </Card>
    </>
  );
};

function updateAgg(type, prevAgg, newAgg) {
  const keys = [
    "amazon",
    "ebay",
    "etsy",
    "online_stores",
    "facebook",
    "tiktok",
  ];
  const nextAgg = { ...prevAgg };
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    if (
      (!type || type === key) &&
      prevAgg &&
      !isEqual(prevAgg[key], newAgg[key])
    ) {
      nextAgg[key] = newAgg[key];
    }
  }

  return nextAgg;
}
