import { gql } from "apollo-boost";

export const DELETE_FILE_AMZ = gql`
  mutation deleteFile($id: ID!, $deleteAmz: Boolean) {
    deleteFile(id: $id, deleteAmz: $deleteAmz)
  }
`;

export const UPLOAD_ORDER_DESIGN = gql`
  mutation uploadOrderDesign(
    $id: ID!
    $input: [NewUploadOrderDesign!]!
    $cancelOtherTask: Boolean!
  ) {
    uploadOrderDesign(id: $id, input: $input, cancelOtherTask: $cancelOtherTask)
  }
`;

export const UPDATE_ORDER = gql`
  mutation updateOrder($input: EditOrder!) {
    updateOrder(input: $input)
  }
`;

export const PAUSE_ORDER = gql`
  mutation pauseOrder($id: ID!, $pause: Boolean!) {
    pauseOrder(id: $id, pause: $pause)
  }
`;

export const CHARGE_BASE_COST = gql`
  mutation chargeBaseCost($orderIds: [ID!]!) {
    chargeBaseCost(orderIds: $orderIds)
  }
`;

export const PASS_ON_HOLD = gql`
  mutation passOnHold($orderIds: [ID!]!) {
    passOnHold(orderIds: $orderIds)
  }
`;

export const RE_SUBMIT_ORDER_TO_FF = gql`
  mutation reSubmitOrderToFulfillment($orderId: ID!) {
    reSubmitOrderToFulfillment(orderId: $orderId)
  }
`;

export const FORCE_PAUSE_ORDER = gql`
  mutation forcePauseOrder($orderId: ID!) {
    forcePauseOrder(orderId: $orderId)
  }
`;

export const CREATE_TRACKING = gql`
  mutation createTracking($input: NewTracking!) {
    createTracking(input: $input) {
      id
      status
      code
      newCode
      comment
    }
  }
`;

export const UPDATE_ORDER_TRACKING = gql`
  mutation updateOrderTracking($orderId: ID!, $code: String!) {
    updateOrderTracking(orderId: $orderId, code: $code)
  }
`;

export const SWITCH_FULFILLMENT = gql`
  mutation switchFulfillment($input: SwitchFulfillmentInput!) {
    switchFulfillment(input: $input)
  }
`;

export const CANCEL_ORDER = gql`
  mutation cancelOrder(
    $orderId: ID
    $originId: ID
    $related: Boolean!
    $cancelOrderStore: Boolean!
  ) {
    cancelOrder(
      orderId: $orderId
      originId: $originId
      related: $related
      cancelOrderStore: $cancelOrderStore
    ) {
      id
      status
      originId
    }
  }
`;

export const RETRACK_FULFILLMENT_ORDER = gql`
  mutation reTrackFulfillmentOrder($orderId: ID!) {
    reTrackFulfillmentOrder(orderId: $orderId)
  }
`;

export const ADMIN_CREATE_TRACKING = gql`
  mutation adminCreateTracking($input: NewTracking!) {
    adminCreateTracking(input: $input) {
      id
      status
      code
    }
  }
`;

export const CREATE_CLAIM = gql`
  mutation createClaim($input: NewClaim!) {
    createClaim(input: $input) {
      id
      type
      comment
      status
    }
  }
`;

export const ADMIN_RECREATE_ORDER_FULFILLMENT = gql`
  mutation adminRecreateOrderFulfillment($orderId: ID!) {
    adminRecreateOrderFulfillment(orderId: $orderId)
  }
`;

export const ADMIN_REVERT_MAPPING_ORDER = gql`
  mutation adminRevertMappingOrder($orderId: ID!, $storeId: ID) {
    adminRevertMappingOrder(orderId: $orderId, storeId: $storeId)
  }
`;

export const UPDATE_ORDER_DESIGN = gql`
  mutation updateOrderDesign($id: ID!, $input: [NewUploadOrderDesign!]!) {
    updateOrderDesign(id: $id, input: $input)
  }
`;

export const FETCH_MERCHIZE_TRACKING = gql`
  mutation fetchMerchizeTracking($fulfillmentOrderIDs: [ID!]) {
    fetchMerchizeTracking(fulfillmentOrderIDs: $fulfillmentOrderIDs)
  }
`;

export const ORDERS_REFUND = gql`
  mutation ordersRefund($ids: [ID!]) {
    ordersRefund(ids: $ids)
  }
`;

export const REFETCH_TOTAL_SALE = gql`
  mutation refreshTotalSale($ids: [ID!]) {
    refreshTotalSale(ids: $ids)
  }
`;

export const CREATE_MANUAL_ORDER = gql`
  mutation createManualOrder($input: NewManualOrder!) {
    createManualOrder(input: $input) {
      id
    }
  }
`;

export const REFUND_ORDER_FACEBOOK = gql`
  mutation refundOrderFacebook($id: String!) {
    refundOrderFacebook(id: $id) {
      id
    }
  }
`;

export const QC_MARK_PRODUCT_DESIGN = gql`
  mutation markProductDesignCheckedByQC($orderId: ID!) {
    markProductDesignCheckedByQC(orderId: $orderId) {
      id
      designCheckedByQC
    }
  }
`;

export const UPDATE_EMAIL_CUSTOMER = gql`
  mutation updateEmailCustomer($orderId: ID!, $email: String!) {
    updateEmailCustomer(orderId: $orderId, email: $email) {
      id
      email
    }
  }
`;

export const SWITCH_STORE_FOR_ORDERS = gql`
  mutation switchStoreForOrder($originIds: [String!]!, $storeId: ID!) {
    switchStoreForOrder(originIds: $originIds, storeId: $storeId)
  }
`;

export const EXPORT_ORDERS_BY_TEMPLATE_OF_CHINA_SUP = gql`
  mutation exportOrdersByTemplateOfChinaSup(
    $filter: OrderFilter!
    $emailToReceiveFileUrl: String
  ) {
    exportOrdersByTemplateOfChinaSup(
      filter: $filter
      emailToReceiveFileUrl: $emailToReceiveFileUrl
    )
  }
`;

export const UPDATE_ORDER_3RD_FULFILLMENT_SHIPPING = gql`
  mutation updateOrder3rdFulfillmentShipping(
    $orderId: ID!
    $newShippingOption: String
  ) {
    updateOrder3rdFulfillmentShipping(
      orderId: $orderId
      newShippingOption: $newShippingOption
    ) {
      id
    }
  }
`;

export const ADD_NOTE_BY_DEV = gql`
  mutation addNoteByDev($input: AddNoteByDevInput!) {
    addNoteByDev(input: $input)
  }
`;

export const UPDATE_ORDER_REVENUE = gql`
  mutation updateOrderRevenue($orderId: ID!, $storeId: ID!, $revenue: Float!) {
    updateOrderRevenue(orderId: $orderId, storeId: $storeId, revenue: $revenue)
  }
`;

export const REFETCH_PRINTIFY_ORDER_INFO = gql`
  mutation refetchPrintifyOrderInfo($orderID: ID!) {
    refetchPrintifyOrderInfo(orderID: $orderID)
  }
`;

export const EXPORT_ORDERS = gql`
  mutation exportOrders($filter: ExportOrderFilter!) {
    exportOrders(filter: $filter) {
      id
      file {
        id
        url
      }
      createdAt
    }
  }
`;

export const UPDATE_ORDER_COST = gql`
  mutation updateOrderCost($input: UpdateOrderCost!) {
    updateOrderCost(input: $input) {
      id
    }
  }
`;

export const SELLER_EXPORT_ORDER = gql`
  mutation sellerExportOrder($input: NewSellerExportOrder!) {
    sellerExportOrder(input: $input)
  }
`;

export const SELLER_EXPORT_ORDER_V2 = gql`
  mutation sellerExportOrderV2($input: NewSellerExportOrder!) {
    sellerExportOrderV2(input: $input)
  }
`;

export const MARK_USED_CALC_ORDER_COST = gql`
  mutation markUsedCalcOrderCost($ids: [ID!]!, $used: Boolean!) {
    markUsedCalcOrderCost(ids: $ids, used: $used)
  }
`;

export const CHANGE_PRODUCT_OF_ORDER = gql`
  mutation changeProductOfOrder($input: ChangeProductOfOrder!) {
    changeProductOfOrder(input: $input)
  }
`;

export const CLONE_ORDER_TO_RESEND = gql`
  mutation cloneOrderToResend($input: CloneOrderToResend!) {
    cloneOrderToResend(input: $input)
  }
`;

export const ADD_ORDER_NOTE = gql`
  mutation addOrderNote($id: ID!, $note: String) {
    addOrderNote(id: $id, note: $note)
  }
`;

export const FETCH_ORDER_FULFILLMENT_STATUS = gql`
  mutation fetchOrderFulfillmentStatus($orderId: ID!) {
    fetchOrderFulfillmentStatus(orderId: $orderId)
  }
`;

export const FETCH_FULFILLMENT_ORDER_STATS = gql`
  mutation fetchFulfillmentOrderStats {
    fetchFulfillmentOrderStats
  }
`;

export const REJECT_CURRENT_DESIGN = gql`
  mutation rejectCurrentDesign($orderID: ID!, $rejectReason: String!) {
    rejectCurrentDesign(orderID: $orderID, rejectReason: $rejectReason)
  }
`;

export const REPORT_PLATFORM_SALE_STATS = gql`
  mutation reportPlatformSaleStats($input: ReportPlatformSaleStatsInput!) {
    reportPlatformSaleStats(input: $input) 
  }
`;

export const UPDATE_ORDER_QUANTITY = gql`
  mutation updateOrderQuantity($orderID: ID!, $qty: Int!) {
    updateOrderQuantity(orderID: $orderID, qty: $qty)
  }
`;

export const UPDATE_CARRIER_FOR_SUP_CHINA = gql`
  mutation updateCarrierForSupChina($orderID: ID!, $carrierID: ID!) {
    updateCarrierForSupChina(orderID: $orderID, carrierID: $carrierID)
  }
`;

export const ADMIN_RECREATE_ORDER_FULFILLMENT_V2 = gql`
  mutation adminRecreateOrderFulfillmentV2($orderId: ID!, $resendRelatedOrder: Boolean) {
    adminRecreateOrderFulfillmentV2(orderId: $orderId, resendRelatedOrder: $resendRelatedOrder)
  }
`;

export const FORCE_CREATE_ORDER_LABEL = gql`
  mutation forceCreateOrderLabel($orderID: String!) {
    forceCreateOrderLabel(orderID: $orderID)
  }
`;

export const UPDATE_DOCUMENT_URL = gql`
  mutation updateDocUrl($orderID: String!, $docUrl: String!) {
    updateDocUrl(orderID: $orderID, docUrl: $docUrl)
  }
`;

export const PASS_ON_HOLD_USE_THIRD_PARTY = gql`
  mutation passOnHoldUseThirdParty($orderIds: [ID!]!) {
    passOnHoldUseThirdParty(orderIds: $orderIds)
  }
`;

export const MARK_AWAITING_BUYER_INFO = gql`
  mutation markAwaitingBuyerInfo($mappingOrderIDs: [ID!]!, $platform: String, $action: String) {
    markAwaitingBuyerInfo(mappingOrderIDs: $mappingOrderIDs, platform: $platform, action: $action)
  }
`;

export const UPDATE_MAPPING_ORDER_NOTE = gql`
  mutation updateMappingOrderNote($mappingOrderIDs: [ID!]!, $platform: String, $content: String) {
    updateMappingOrderNote(mappingOrderIDs: $mappingOrderIDs, platform: $platform, content: $content)
  }
`;

export const CREATE_PRODUCT_ORDER_FROM_MAPPING = gql`
  mutation createProductOrderFromMapping($input: NewProductMapping!){
    createProductOrderFromMapping(input: $input) {
      id
      title
    }
  }
`;