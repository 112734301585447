import {lazy} from 'react';

import { DocumentationsPage } from "../components/documentations";
import { ExportAmazonAddPolaris } from "../components/export-amazon/ExportAmazonAddPolaris";
import { ExportAmazonEditPolaris } from "../components/export-amazon/ExportAmazonEditPolaris";
import { ExportAmazonViewConfig } from "../components/export-amazon/ExportAmazonViewConfig";
import { ViewProductsPolaris } from "../components/export-amazon/ViewProductsPolaris";
import { AddMemberPolaris } from "../components/members/AddMemberPolaris";
import { EditMemberPolaris } from "../components/members/EditMemberPolaris";
import { AddCollectionPolaris } from "../components/product/collections/AddCollectionPolaris";
import { EditCollectionPolaris } from "../components/product/collections/EditCollectionPolaris";
import { AddProductDivisionPolaris } from "../components/product/divisions/AddProductDivisionPolaris";
import { EditProductDivisionPolaris } from "../components/product/divisions/EditProductDivisionPolaris";
import { Clone as CloneProductFromPrintFile } from "../components/product/print-file/Clone";
import { Create as CreateProductFromPrintFile } from "../components/product/print-file/Create";
import { Edit as EditProductFromPrintFile } from "../components/product/print-file/Edit";
import { TemplatesPolaris } from "../components/product/templates/TemplatesPolaris";
import { EditStorePolaris } from "../components/store/EditStorePolaris";
import { CreateStore } from "../components/store/fb-marketplace/components/CreateStore";
import { Edit } from "../components/store/fb-marketplace/components/Edit";
import { GroupAddPolaris } from "../components/store/group/GroupAddPolaris";
import { GroupEditPolaris } from "../components/store/group/GroupEditPolaris";
import SwitchToAPIStorePage from "../components/store/SwitchToAPIStore";
import MainLayout from "../layout/MainLayout";
import { MembersPagePolaris } from "../pages/marketplace-manager/MembersPagePolaris";
import {
  AmazonChannelPage,
  EbayChannelPage,
  EtsyChannelPage,
  FacebookMKPage,
} from "../pages/reports";
import AddAmazonAccount from "../pages/seller/AddAmazonAccount";
import AddCampaignProduct from "../pages/seller/AddCampaignProduct";
import AddEtsyAccount from "../pages/seller/AddEtsyAccount";
import AddProductPage from "../pages/seller/AddProduct";
import AddTiktokAccountPage from "../pages/seller/AddTiktokAccount";
import Amazon from "../pages/seller/Amazon";
import Apis from "../pages/seller/Apis";
import ArtistTaskPage from "../pages/seller/ArtistTaskPage";
import { SellerClaimsPagePolaris } from "../pages/seller/ClaimsPolaris";
import { CloneProduct } from "../pages/seller/CloneProduct";
import { CloneProductCampaign } from "../pages/seller/CloneProductCampaign";
import Collections from "../pages/seller/Collections";
import { CreateManualOrderPagePolaris } from "../pages/seller/CreateManualOrderPagePolaris";
import { DesignTasksPagePolaris } from "../pages/seller/DesignTasksPagePolaris";
import Ebay from "../pages/seller/Ebay";
import EditProductPage from "../pages/seller/EditProduct";
import EditProductCampaign from "../pages/seller/EditProductCampaign";
import Etsy from "../pages/seller/Etsy";
import { ExportAmazonPagePolaris } from "../pages/seller/ExportAmazonPagePolaris";
import { ExportTrackingPagePolaris } from "../pages/seller/ExportTrackingPagePolaris";
import { FBMarketplacePagePolaris } from "../pages/seller/FBMarketplacePagePolaris";
import { GroupPagePolaris } from "../pages/seller/GroupPagePolaris";
import { HistoryExportTrackingPagePolaris } from "../pages/seller/HistoryExportTrackingPagePolaris";
import { IdeaTaskPagePolaris } from "../pages/seller/IdeaTaskPagePolaris";
import { MappingOrderPagePolaris } from "../pages/seller/MappingOrdersPagePolaris";
import OnlineDesignTeamPage from "../pages/seller/OnlineDesignTeamPage";
import { OrdersPagePolaris } from "../pages/seller/OrdersPagePolaris";
import PersonalizedPage from "../pages/seller/PersonalizedPage";
import ProductAssortment from "../pages/seller/ProductAssortment";
import ProductCrawler from "../pages/seller/ProductCrawler";
import { ProductCrawlerPagePolaris } from "../pages/seller/ProductCrawlerPagePolaris";
import { ProductCrawlersPagePolaris } from "../pages/seller/ProductCrawlersPagePolaris";
import { ProductDivisionPagePolaris } from "../pages/seller/ProductDivisionsPagePolaris";
import { ProductFromPrintFilePage } from "../pages/seller/ProductFromPrintFilePage";
import { ProductsIgnoredPagePolaris } from "../pages/seller/ProductsIgnoredPagePolaris";
import { ProductsPagePolaris } from "../pages/seller/ProductsPagePolaris";
import { SwitchStoreForOrderPage } from "../pages/seller/SwitchStoreForOrderPage";
import Tags from "../pages/seller/Tags";
import TiktokPage from "../pages/seller/TiktokPage";

export default [
  {
    path: "/marketplace-manager/stores/amazon",
    exact: true,
    roles: ["Seller"],
    teamRole: "Marketplace Manager",
    component: Amazon,
    layout: MainLayout,
  },
  {
    path: "/marketplace-manager/stores/amazon/add",
    exact: true,
    component: AddAmazonAccount,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Marketplace Manager",
  },
  {
    path: "/marketplace-manager/stores/ebay",
    exact: true,
    roles: ["Seller"],
    teamRole: "Marketplace Manager",
    component: Ebay,
    layout: MainLayout,
  },
  {
    path: "/marketplace-manager/stores/etsy",
    exact: true,
    roles: ["Seller"],
    teamRole: "Marketplace Manager",
    component: Etsy,
    layout: MainLayout,
  },
  {
    path: "/marketplace-manager/stores/etsy/add",
    exact: true,
    component: AddEtsyAccount,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Marketplace Manager",
  },
  {
    path: "/marketplace-manager/stores/tiktok",
    exact: true,
    roles: ["Seller"],
    teamRole: "Marketplace Manager",
    component: TiktokPage,
    layout: MainLayout,
  },
  {
    path: "/marketplace-manager/stores/tiktok/add",
    exact: true,
    component: AddTiktokAccountPage,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Marketplace Manager",
  },
  {
    path: "/marketplace-manager/store/edit/:id",
    exact: true,
    component: EditStorePolaris,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Marketplace Manager",
  },
  {
    path: "/marketplace-manager/store/switch/:id",
    exact: true,
    component: SwitchToAPIStorePage,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Marketplace Manager",
  },
  {
    path: "/marketplace-manager/stores/fb-marketplace",
    exact: true,
    component: FBMarketplacePagePolaris,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Marketplace Manager",
    private: true,
  },
  {
    path: "/marketplace-manager/stores/fb-marketplace/add",
    exact: true,
    component: CreateStore,
    title: "Create store",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Marketplace Manager",
    private: true,
  },
  {
    path: "/marketplace-manager/stores/fb-marketplace/edit/:id",
    exact: true,
    component: Edit,
    title: "Create store",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Marketplace Manager",
    private: true,
  },
  {
    path: "/marketplace-manager/products",
    exact: true,
    component: ProductsPagePolaris,
    layout: MainLayout,
    title: "Products",
    roles: ["Seller"],
    teamRole: "Marketplace Manager",
  },
  {
    path: "/marketplace-manager/products/edit/:id",
    exact: true,
    component: EditProductPage,
    layout: MainLayout,
    title: "Edit Product",
    roles: ["Seller"],
    teamRole: "Marketplace Manager",
  },
  {
    path: "/marketplace-manager/products/add",
    exact: true,
    component: AddProductPage,
    layout: MainLayout,
    title: "Add Product",
    roles: ["Seller"],
    teamRole: "Marketplace Manager",
  },
  {
    path: "/marketplace-manager/products/add/:id",
    exact: true,
    component: AddProductPage,
    layout: MainLayout,
    title: "Add Product",
    roles: ["Seller"],
    teamRole: "Marketplace Manager",
  },
  {
    path: "/marketplace-manager/products/clone",
    exact: true,
    component: CloneProduct,
    layout: MainLayout,
    title: "Clone Product",
    roles: ["Seller"],
    teamRole: "Marketplace Manager",
  },
  {
    path: "/marketplace-manager/products/add-campaign",
    exact: true,
    component: AddCampaignProduct,
    layout: MainLayout,
    title: "Add Campaign Product",
    roles: ["Seller"],
    teamRole: "Marketplace Manager",
  },
  {
    path: "/marketplace-manager/products/add-campaign/:id",
    exact: true,
    component: AddCampaignProduct,
    layout: MainLayout,
    title: "Add Campaign Product",
    roles: ["Seller"],
    teamRole: "Marketplace Manager",
  },
  {
    path: "/marketplace-manager/products/clone-campaign",
    exact: true,
    component: CloneProductCampaign,
    layout: MainLayout,
    title: "Clone Campaign Product",
    roles: ["Seller"],
    teamRole: "Marketplace Manager",
  },
  {
    path: "/marketplace-manager/products/campaign/:id",
    exact: true,
    component: EditProductCampaign,
    layout: MainLayout,
    title: "Edit Product Campaign",
    roles: ["Seller"],
    teamRole: "Marketplace Manager",
  },
  {
    path: "/marketplace-manager/collections",
    exact: true,
    component: Collections,
    layout: MainLayout,
    title: "Collections",
    roles: ["Seller"],
    teamRole: "Marketplace Manager",
  },
  {
    path: "/marketplace-manager/add/collection",
    exact: true,
    // component: AddCollections,
    component: AddCollectionPolaris,
    layout: MainLayout,
    title: "Add Collection",
    roles: ["Seller"],
    teamRole: "Marketplace Manager",
  },
  {
    path: "/marketplace-manager/edit/collection/:id",
    exact: true,
    // component: EditCollection,
    component: EditCollectionPolaris,
    layout: MainLayout,
    title: "Edit Collection",
    roles: ["Seller"],
    teamRole: "Marketplace Manager",
  },
  {
    path: "/marketplace-manager/templates",
    exact: true,
    component: TemplatesPolaris,
    layout: MainLayout,
    title: "Templates",
    roles: ["Seller"],
    teamRole: "Marketplace Manager",
  },
  {
    path: "/marketplace-manager/tags",
    exact: true,
    component: Tags,
    layout: MainLayout,
    title: "Tags",
    roles: ["Seller"],
    teamRole: "Marketplace Manager",
  },
  {
    path: "/marketplace-manager/orders",
    exact: true,
    // component: SellerOrders,
    component: OrdersPagePolaris,
    layout: MainLayout,
    title: "Orders",
    roles: ["Seller"],
    teamRole: "Marketplace Manager",
  },
  {
    path: "/marketplace-manager/mapping",
    exact: true,
    // component: MappingOrders,
    component: MappingOrderPagePolaris,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Marketplace Manager",
  },
  {
    path: "/marketplace-manager/claims",
    exact: true,
    // component: SellerClaimsPage,
    component: SellerClaimsPagePolaris,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Marketplace Manager",
  },
  {
    path: "/marketplace-manager/design-tasks",
    exact: true,
    // component: DesignTasksPage,
    component: DesignTasksPagePolaris,
    title: "All Tasks",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Marketplace Manager",
  },
  {
    path: "/marketplace-manager/artist-tasks",
    exact: true,
    component: ArtistTaskPage,
    title: "Artist Task page",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Marketplace Manager",
  },
  {
    path: "/marketplace-manager/team-members",
    exact: true,
    component: MembersPagePolaris,
    layout: MainLayout,
    title: "Members",
    roles: ["Seller"],
    teamRole: "Marketplace Manager",
  },
  {
    path: "/marketplace-manager/add-team-member",
    exact: true,
    // component: AddTeamMember,
    component: AddMemberPolaris,
    layout: MainLayout,
    title: "Add New Member",
    roles: ["Seller"],
    teamRole: "Marketplace Manager",
  },
  {
    path: "/marketplace-manager/online-design-teams",
    exact: true,
    component: OnlineDesignTeamPage,
    layout: MainLayout,
    title: "Design Online Team",
    roles: ["Seller"],
    teamRole: "Marketplace Manager",
  },
  {
    path: "/marketplace-manager/edit-team-member/:id",
    exact: true,
    // component: EditTeamMember,
    component: EditMemberPolaris,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Marketplace Manager",
  },
  {
    path: "/tools/product-assortment",
    exact: true,
    component: ProductAssortment,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Marketplace Manager",
    private: true,
  },
  {
    path: "/tools/product-crawlers",
    exact: true,
    // component: ProductCrawlersPage,
    component: ProductCrawlersPagePolaris,
    layout: MainLayout,
    title: "All Crawlers",
    roles: ["Seller"],
    teamRole: "Marketplace Manager",
    private: true,
  },
  {
    path: "/tools/product-crawler",
    exact: true,
    component: ProductCrawler,
    // component: AddProductCrawlerPagePolaris,
    layout: MainLayout,
    title: "Product crawler",
    roles: ["Seller"],
    teamRole: "Marketplace Manager",
  },
  {
    path: "/tools/product-crawlers/:id",
    exact: true,
    // component: ProductCrawlerByIdPage,
    component: ProductCrawlerPagePolaris,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Marketplace Manager",
    private: true,
  },
  {
    path: "/marketplace-manager/product-divisions",
    exact: true,
    component: ProductDivisionPagePolaris,
    title: "Product Divisions",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Marketplace Manager",
  },
  {
    path: "/marketplace-manager/product-divisions/add",
    exact: true,
    component: AddProductDivisionPolaris,
    title: "Add Product Divisions",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Marketplace Manager",
  },
  {
    path: "/marketplace-manager/product-divisions/edit/:id",
    exact: true,
    component: EditProductDivisionPolaris,
    title: "Add Product Divisions",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Marketplace Manager",
  },
  {
    path: "/marketplace-manager/products-ignored",
    exact: true,
    component: ProductsIgnoredPagePolaris,
    title: "Product Ignored",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Marketplace Manager",
    private: true,
  },
  {
    path: "/marketplace-manager/export-amazon",
    exact: true,
    component: ExportAmazonPagePolaris,
    title: "Export for Amazon",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Marketplace Manager",
  },
  {
    path: "/marketplace-manager/export-amazon/add",
    exact: true,
    component: ExportAmazonAddPolaris,
    title: "Export for Amazon",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Marketplace Manager",
  },
  {
    path: "/marketplace-manager/export-amazon/:id",
    exact: true,
    component: ExportAmazonEditPolaris,
    title: "Export for Amazon",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Marketplace Manager",
  },
  {
    path: "/marketplace-manager/export-amazon/view-products/:id",
    exact: true,
    component: ViewProductsPolaris,
    title: "View products",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Marketplace Manager",
  },
  {
    path: "/marketplace-manager/export-tracking",
    exact: true,
    component: ExportTrackingPagePolaris,
    title: "Export tracking group",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Marketplace Manager",
  },
  {
    path: "/marketplace-manager/history-export-tracking",
    exact: true,
    component: HistoryExportTrackingPagePolaris,
    title: "History export tracking group",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Marketplace Manager",
  },
  {
    path: "/marketplace-manager/export-amazon/view-configs/:id",
    exact: true,
    component: ExportAmazonViewConfig,
    title: "View products",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Marketplace Manager",
  },
  {
    path: "/marketplace-manager/personalized/artworks",
    exact: true,
    component: PersonalizedPage,
    title: "Artworks",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Marketplace Manager",
  },
  {
    path: "/marketplace-manager/personalized/cliparts",
    exact: true,
    component: PersonalizedPage,
    title: "Cliparts",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Marketplace Manager",
  },
  {
    path: "/marketplace-manager/personalized/fonts",
    exact: true,
    component: PersonalizedPage,
    title: "Fonts",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Marketplace Manager",
  },
  {
    path: "/marketplace-manager/create-manual-order",
    exact: true,
    component: CreateManualOrderPagePolaris,
    title: "Create Manual Order",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Marketplace Manager",
  },
  {
    path: "/marketplace-manager/documentations",
    exact: true,
    component: DocumentationsPage,
    title: "Documentations Page",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Marketplace Manager",
  },
  // {
  //   path: "/marketplace-manager/reports/etsy",
  //   exact: true,
  //   component: EtsyChannelPage,
  //   title: "Etsy Channel Page",
  //   layout: MainLayout,
  //   roles: ["Seller"],
  //   teamRole: "Marketplace Manager",
  // },
  // {
  //   path: "/marketplace-manager/reports/ebay",
  //   exact: true,
  //   component: EbayChannelPage,
  //   title: "Ebay Channel Page",
  //   layout: MainLayout,
  //   roles: ["Seller"],
  //   teamRole: "Marketplace Manager",
  // },
  // {
  //   path: "/marketplace-manager/reports/amazon",
  //   exact: true,
  //   component: AmazonChannelPage,
  //   title: "Amazon Channel Page",
  //   layout: MainLayout,
  //   roles: ["Seller"],
  //   teamRole: "Marketplace Manager",
  // },
  // {
  //   path: "/marketplace-manager/reports/facebook-mk",
  //   exact: true,
  //   component: FacebookMKPage,
  //   title: "Facebook Marketplace Channel Page",
  //   layout: MainLayout,
  //   roles: ["Seller"],
  //   teamRole: "Marketplace Manager",
  //   private: true,
  // },
  {
    path: "/marketplace-manager/product-from-print-file",
    exact: true,
    component: ProductFromPrintFilePage,
    title: "Product From Print File Page",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Marketplace Manager",
    private: true,
  },
  {
    path: "/marketplace-manager/product-from-print-file/create",
    exact: true,
    component: CreateProductFromPrintFile,
    title: "Create Product From Print File Page",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Marketplace Manager",
    private: true,
  },
  {
    path: "/marketplace-manager/product-from-print-file/edit/:id",
    exact: true,
    component: EditProductFromPrintFile,
    title: "Edit Product From Print File Page",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Marketplace Manager",
    private: true,
  },
  {
    path: "/marketplace-manager/product-from-print-file/clone/:id",
    exact: true,
    component: CloneProductFromPrintFile,
    title: "Clone Product From Print File Page",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Marketplace Manager",
    private: true,
  },
  {
    path: "/marketplace-manager/settings/apis",
    exact: true,
    component: Apis,
    title: "API Keys",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Marketplace Manager",
  },
  {
    path: "/marketplace-manager/switch-store-for-order",
    exact: true,
    title: "Switch Store For Order Page",
    component: SwitchStoreForOrderPage,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Marketplace Manager",
    private: true,
  },
  {
    path: "/marketplace-manager/stores/groups",
    exact: true,
    component: GroupPagePolaris,
    title: "Group",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Marketplace Manager",
  },
  {
    path: "/marketplace-manager/stores/groups/add",
    exact: true,
    component: GroupAddPolaris,
    title: "Add Group",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Marketplace Manager",
  },
  {
    path: "/marketplace-manager/stores/groups/edit/:id",
    exact: true,
    component: GroupEditPolaris,
    title: "Edit Group",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Marketplace Manager",
  },
  {
    path: "/marketplace-manager/design-tasks/ideas",
    exact: true,
    component: IdeaTaskPagePolaris,
    title: "Idea Task page",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Marketplace Manager",
  },
  {
    path: "/marketplace-manager/tiktok-hub/promotions",
    exact: true,
    component: lazy(() => import("../pages/seller/tiktok/promotion")),
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Marketplace Manager",
  },
  {
    path: "/marketplace-manager/tiktok-hub/promotions/create",
    exact: true,
    component: lazy(() => import("../pages/seller/tiktok/createPromotion")),
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Marketplace Manager",
  },
];
