import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  Button,
  Checkbox,
  ChoiceList,
  Labelled,
  Modal,
  Spinner,
  Stack,
  TextField,
} from "@shopify/polaris";
import get from "lodash/get";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { SELLER_EXPORT_ORDER_V2 } from "../../../graphql/mutations";
import { handleError, reducerFn } from "../../../helper";
import useToggle from "../../../hooks/useToggle";
import { useToastContext } from "../../shared/ToastContext";
import { useOrderContext } from "../OrdersPolaris";
import { LIST_EXPORT_TEMPLATE } from "./AdminExportOrdersPolaris";

export function ExportOrdersForSupChina({ filter, data }) {
  // State
  const [open, toggleOpen] = useToggle(false);
  const [clicked, setClicked] = useState(false);
  const [disbaled, setDisabled] = useState(false);
  const [input, setInput] = useState("");
  const [selected, setSelected] = useState(["default"]);
  const [options, setOptions] = useState([]);

  const [state, setState] = React.useReducer(reducerFn, {
    includeResendTimes: false,
  });

  // Context
  const { toggleToast, setNotify } = useToastContext();
  const ctx = useOrderContext();

  // Query
  const {
    data: data2,
    loading: loading2,
    error: error2,
  } = useQuery(LIST_EXPORT_TEMPLATE, {
    fetchPolicy: "cache-and-network",
  });

  const [exportOrders, { loading }] = useMutation(SELLER_EXPORT_ORDER_V2, {
    onCompleted: (res) => {
      if (res) {
        setNotify({
          msg: "File is generating.",
          err: false,
        });
      }
    },
    onError: (error) => {
      setNotify({
        msg: handleError(error.toString()),
        err: true,
      });
    },
  });

  useEffect(() => {
    const newOptions =
      data2?.exportTemplates?.length > 0
        ? data2.exportTemplates.map((t) => ({
            value: t.id,
            label: t.name,
          }))
        : [];
    setOptions([
      { value: "default", label: "Default Template" },
      ...newOptions,
    ]);
  }, [data2]);

  const handleStateChange = React.useCallback((value, id) => {
    setState({ [id]: value });
  }, []);

  const handleSubmit = useCallback(() => {
    setClicked(true);
    toggleToast(true);
    exportOrders({
      variables: {
        input: {
          filter,
          emailToReceiveFileUrl: input,
          templateId: selected[0] === "default" ? null : selected[0],
          orderIds:
            ctx?.itemsChecked?.length > 0 ? ctx.itemsChecked : undefined,
          includeResendTimes: state.includeResendTimes,
        },
      },
    }).finally(() => {
      setClicked(false);
      toggleOpen();
    });
  }, [
    filter,
    selected,
    input,
    toggleToast,
    ctx?.itemsChecked,
    toggleOpen,
    exportOrders,
    state.includeResendTimes,
  ]);

  useEffect(() => {
    if (!clicked) return;

    setDisabled(true);
    let id = setTimeout(() => {
      setDisabled(false);
    }, 1000 * 60 * 5); // 5 minutes

    return () => {
      clearTimeout(id);
    };
  }, [clicked]);

  const total = get(data, "orders.total", 0);
  const canShow = total > 0 && total <= 10_000;

  return canShow ? (
    <Fragment>
      <Button primary onClick={toggleOpen} children="Export Orders" />
      <Modal
        title="Export orders"
        open={open}
        onClose={toggleOpen}
        sectioned
        primaryAction={{
          content: "Submit",
          loading: loading,
          onAction: handleSubmit,
          disabled: loading ? false : disbaled,
        }}
        secondaryActions={[{ content: "Cancel", onAction: toggleOpen }]}
      >
        <Stack vertical>
          <TextField
            value={input}
            placeholder="Email to receive export result"
            onChange={setInput}
            label="Email"
            helpText={
              "We are generating the export file. An email will be send when it done."
            }
          />

          <div>
            <Checkbox
              id="includeResendTimes"
              label="Include Resend Times"
              checked={state.includeResendTimes}
              onChange={handleStateChange}
            />
          </div>
          <div>
            <Labelled label="Choose export template" />
            {loading2 ? (
              <Spinner size="small" />
            ) : error2 ? (
              <div>Error: {handleError(error2.toString())}</div>
            ) : (
              <ChoiceList
                title="Choose export template"
                titleHidden
                choices={options}
                selected={selected}
                onChange={setSelected}
              />
            )}
          </div>
        </Stack>
      </Modal>
    </Fragment>
  ) : null;
}
