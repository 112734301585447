import React from "react";
import styled from "styled-components";
import { TRACKING_STATUS_DAYS } from "../../../constants/index";
import { checkRole } from "../../../helper";
import { ORDER_STATUS, USER_ROLE } from "../../../variable";
import { AdminExportOrdersPolaris } from "./AdminExportOrdersPolaris";
import { ExportButtonByDayPolaris } from "./ExportButtonByDayPolaris";
import { ExportButtonPolaris } from "./ExportButtonPolaris";
import { ExportOrdersForSupChina } from "./ExportOrdersForSupChina";
import { ExportOrdersProcessing } from "./ExportOrdersProcessing";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
`;

export const PageActionsPolaris = (props) => {
  const {
    filter,
    role,
    isAdmin,
    KEY_DAY_10,
    KEY_DAY_12,
    data,
    currentUser,
    refetch,
  } = props;
  const roleProps = role;
  const { isSeller, isStoreManager, isSupplier } = checkRole(currentUser);

  let showExportOrders = false;
  if (
    [ORDER_STATUS.ReadyToPrint].includes(filter.status) &&
    [USER_ROLE.Supplier].includes(roleProps)
  ) {
    showExportOrders = true;
  }

  let showExportBtnByDays = false;
  let rangeDay = null;
  if (
    [ORDER_STATUS.Fulfilled].includes(filter.status) &&
    [...Object.keys(TRACKING_STATUS_DAYS)].includes(filter.trackingStatus)
  ) {
    showExportBtnByDays = true;
    rangeDay =
      filter.trackingStatus &&
      filter.trackingStatus.split("daysnotfound").length
        ? filter.trackingStatus.split("daysnotfound")[0]
        : null;
  }

  let showAdminExportOrders = false;
  if (
    [USER_ROLE.Supplier].includes(role) &&
    isAdmin &&
    [
      ORDER_STATUS.PendingPayment,
      ORDER_STATUS.ReadyToPrint,
      ORDER_STATUS.Printing,
      ORDER_STATUS.Fulfilled,
    ].includes(filter.status) &&
    ![KEY_DAY_10, KEY_DAY_12].includes(filter.trackingStatus)
  ) {
    showAdminExportOrders = true;
  }

  const showExportOrdersProcessing =
    ((filter.supplierId != null && isSeller) || isSupplier) &&
    [ORDER_STATUS.Processing].includes(filter.status);
  const showExportForSupChina =
    isSeller && ![ORDER_STATUS.Processing].includes(filter.status); // isStoreManager

  return (
    <Container>
      {showExportForSupChina && (
        <ExportOrdersForSupChina filter={filter} data={data} />
      )}
      {showExportOrders && <ExportButtonPolaris filter={filter} />}
      {showExportBtnByDays && (
        <ExportButtonByDayPolaris filter={filter} rangeDay={rangeDay} />
      )}
      {showAdminExportOrders && <AdminExportOrdersPolaris filter={filter} />}
      {showExportOrdersProcessing && (
        <ExportOrdersProcessing data={data} refetch={refetch} supplierId={filter.supplierId}/>
      )}
    </Container>
  );
};
