import { useMutation } from "@apollo/react-hooks";
import { Modal, TextField } from "@shopify/polaris";
import React from "react";
import { UPDATE_ORDER_QUANTITY } from "../../../graphql/mutations";
import { handleError } from "../../../helper";
import { useToastContext } from "../../shared/ToastContext";

const UpdateQuantity = ({ open, onClose, value, refetch }) => {
  // Context
  const { toggleToast, setNotify } = useToastContext();
  const [qty, setQty] = React.useState(value?.quantity + "" || "1");

  const timeoutRef = React.useRef(null);
  const [updateQuantity, { loading }] = useMutation(UPDATE_ORDER_QUANTITY, {
    onCompleted: () => {
      setNotify({ msg: "Update order quantity success", err: false });
      if (refetch) {
        timeoutRef.current && clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
          refetch();
        }, 1500);
      }

      onClose && onClose(false);
    },
    onError: (err) => {
      setNotify({ msg: handleError(err?.toString()), err: true });
    },
  });

  const handleSubmit = React.useCallback(() => {
    if (!value?.id) return;

    toggleToast && toggleToast();
    setNotify && setNotify({ msg: null, err: false });
    updateQuantity({
      variables: {
        orderID: value.id,
        qty: parseInt(qty),
      },
    }).finally(() => {
      onClose();
    });
  }, [value?.id, qty, updateQuantity, toggleToast, setNotify]);

  return (
    <Modal
      title="Fetch Order's Status"
      sectioned
      open={open}
      onClose={onClose}
      primaryAction={{
        content: "Submit",
        onAction: handleSubmit,
        loading: loading,
      }}
      secondaryActions={[{ content: "Cancel", onAction: onClose }]}
    >
      <TextField
        label="Quantity"
        value={qty}
        onChange={setQty}
        placeholder="Enter order's quantity"
        error={qty < 1 ? "Quantity is required" : ""}
        type="number"
        min={1}
      />
    </Modal>
  );
};

export default UpdateQuantity;
