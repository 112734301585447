import { Button, ButtonGroup } from "@shopify/polaris";
import { DeleteMajorMonotone } from "@shopify/polaris-icons";
import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { useAppContext } from "../../../context";
import { checkRole } from "../../../helper";
import useToggle from "../../../hooks/useToggle";
import { PLATFORMS, PLATFORM_FACEBOOK } from "../../../variable";
import { MappingOrderActionPolaris } from "../MappingOrderActionPolaris";
import { SplitOrder } from "./SplitOrder";
import { Trash } from "./Trash";
import MoreActions from "./MoreActions";
import SuspenseComp from "../../shared/SuspenseComp";
import { MapActionProvider } from "../context";

const MapContainer = React.lazy(() => import("../components/MapContainer"));

const Container = styled.div``;

export const Actions = ({ item, actionCompleted, setDoAction, refetch }) => {
  // State
  const [open, toggleOpen] = useToggle(false);
  const [action, setAction] = useState(null);

  // Context
  const { currentUser } = useAppContext();
  const { isDesignLeader } = checkRole(currentUser);

  // Handle action
  const onAction = useCallback(
    (action) => {
      toggleOpen(true);
      setAction(action);
      setDoAction(true);
    },
    [toggleOpen],
  );

  const onClose = useCallback(() => {
    setAction(null);
    toggleOpen(false);
    setDoAction(false);
  }, [toggleOpen]);

  const handleCompleted = useCallback(() => {
    onClose();
    refetch && refetch();
  }, [onClose, refetch]);

  // Markup
  const props = {
    ids: item?.id ? [item.id] : [],
    onClose: onClose,
    open,
    isMoreAction: true,
    handleCompleted: actionCompleted,
  };

  const platform = item?.store?.platform;
  const platformFB = platform === PLATFORM_FACEBOOK;
  const isEtsy = platform === PLATFORMS.Etsy;

  const showSplit = item?.quantity && parseInt(item.quantity) > 1 && platformFB;
  const actionsMarkup = {
    map: (
      // <MappingOrderActionPolaris
      //   open={open}
      //   toggleModal={onClose}
      //   item={item}
      //   onCompleted={handleCompleted}
      // />
      <MapContainer
        open={open}
        onCompleted={handleCompleted}
        onClose={onClose}
      />
    ),
    trash: <Trash {...props} />,
    splitOrder: (
      <SplitOrder {...props} item={item} onCompleted={handleCompleted} />
    ),
  };

  return isDesignLeader ? null : (
    <Container>
      <ButtonGroup>
        <Button primary onClick={() => onAction("map")}>
          Map
        </Button>
        {showSplit && (
          <Button onClick={() => onAction("splitOrder")} disabled={!showSplit}>
            Split
          </Button>
        )}

        {isEtsy && <MoreActions record={item} refetch={refetch} />}
        <Button onClick={() => onAction("trash")} icon={DeleteMajorMonotone} />
      </ButtonGroup>
      <MapActionProvider onCompleted={handleCompleted} item={item}>
        <SuspenseComp fallback={null}>{actionsMarkup[action]}</SuspenseComp>
      </MapActionProvider>
    </Container>
  );
};
