import { gql } from 'apollo-boost'

export const CREATE_TIKTOK_PROMOTION = gql`
  mutation createTiktokPromotion($input: CreateTiktokPromotionInput!) {
    createTiktokPromotion(input: $input)
  }
`;

export const UPDATE_TIKTOK_PROMOTION = gql`
  mutation updateTiktokPromotion($input: UpdateTiktokPromotionInput!) {
    createTiktokPromotion(input: $input)
  }
`;

export const DELETE_TIKTOK_PROMOTION = gql`
  mutation deleteTiktokPromotion($promotionID: ID!) {
    createTiktokPromotion(promotionID: $promotionID)
  }
`;

export const TIKTOK_FETCH_PRODUCTS_BY_STORE = gql`
  mutation tiktokFetchProductsByStore($storeID: String!) {
    tiktokFetchProductsByStore(storeID: $storeID)
  }
`;
